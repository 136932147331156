import React from "react";
import cx from "classnames";
import { navigate } from "gatsby-link";
import { graphql, Link, PageProps } from "gatsby";
import { Button, ButtonVariant, Table } from "brass-ui-kit";
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";

import * as styles from "./careers.module.scss";

import { BrandArrow, Play } from "assets/media/svgs";

import pagesMeta from "data/pagesMeta";
import { BRASS_WHY_URL, BRASS_TALENT_NETWORK_FORM } from "data/config";
import { CAREERS_DATA } from "data/pages";

import { Hero, CustomersGrid } from "components/careers";
import { CustomVideoPlayer, GetStarted } from "components/design-system";
import { HighlightCard } from "components/design-system";
import SEO from "components/SEO";

const CareersPage: React.FC<PageProps> = ({ data }) => {
  const [activeImage, setActiveImage] = React.useState(0);

  const {
    allJavascriptFrontmatter: {
      edges: [
        {
          node: { frontmatter: pageData },
        },
      ],
    },
    careers: { edges: openings },
  } = data as any;

  const formattedOpenings = openings[0].node.data.map((opening: any) => ({
    id: opening.id,
    ...opening.attributes,
    location: `${opening.attributes.city}, ${opening.attributes.country}`,
  }));

  const [showVideoPlayer, setShowVideoPlayer] = React.useState(false);

  React.useEffect(() => {
    if (showVideoPlayer) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showVideoPlayer]);

  return (
    <>
      <SEO {...pagesMeta.careers} />
      <Hero images={pageData.heroImages} />

      <section className={styles.Careers_info}>
        <div>
          <span className={styles.Careers_subtitle}>AT BRASS, WE...</span>
          <h2 className={styles.Careers_title}>work on important problems</h2>
        </div>
        <div>
          <p className={styles.Careers_body}>
            Brass means economic empowerment.
            <br />
            <br />
            Millions of businesses in emerging markets struggle today. For
            successful operation and growth. These businesses are more than 90%
            of all businesses, contribute 50% to their country's GDP and employ
            more than 70% of their people.
            <br />
            <br />
            There is no better time than now to become a partner to these
            businesses, help them grow and see the important impact they can
            make to their community, and the overall boost that would mean to
            everyone's wellbeing.
          </p>

          <Button as={"a"} href={BRASS_WHY_URL} variant={ButtonVariant.Primary}>
            Read our Story
          </Button>
        </div>
      </section>

      <section>
        <CustomersGrid items={pageData.customerData} />
      </section>

      <section className={styles.Careers_values}>
        <div className={styles.Careers_values_container}>
          <div className={styles.Careers_values_heading}>
            <h2 className={styles.Careers_title}>What we value the most</h2>
            <p className={styles.Careers_body}>
              We must never forget our values. It is how we make the most
              important decisions about our work, relationships, customer
              service and everything that is critical to our business.
            </p>
          </div>
          <div className={styles.Careers_values_cards}>
            <HighlightCard size="large" items={CAREERS_DATA.VALUES} />
          </div>
        </div>
      </section>

      <section className={styles.Careers_inside}>
        {showVideoPlayer && (
          <CustomVideoPlayer
            handleClose={() => {
              setShowVideoPlayer(false);
            }}
            source="vimeo"
            show={showVideoPlayer}
            videoID="642740582?h=f6d0e099d3"
          />
        )}
        <div className={styles.Careers_inside_video}>
          <div className={styles.Careers_inside_image}>
            <StaticImage
              as="span"
              alt="hero"
              loading="eager"
              src="../../assets/media/images/careers/spotlight.png"
            />
            <div className={styles.Careers_inside_image_action}>
              <Play
                onClick={() => {
                  setShowVideoPlayer(true);
                }}
              />
            </div>
          </div>
        </div>
        <div className={styles.Careers_inside_container}>
          <div className={styles.Careers_inside_heading}>
            <h2 className={styles.Careers_title}>Life at Brass</h2>
            <p className={styles.Careers_body}>
              Go further and come join a team of problem solvers, artists,
              entrepreneurs, engineers and designers, customer service providers
              and creators in their best form, doing their best work in creating
              a new reality for local businesses everywhere.
            </p>
          </div>
        </div>

        <section className={styles.Careers_spotlight}>
          <div className={styles.Careers_spotlight_container}>
            <div>
              <h2 className={styles.Careers_title}>Our people spotlight</h2>
              <p className={styles.Careers_body}>
                Life at Brass is fun, exciting, safe and of course, rewarding.
              </p>
            </div>

            <div className={styles.Careers_spotlight_people_laptop}>
              {pageData.spotlight.map(
                ({ sex, image, team, name, link }: any, index: number) => {
                  const formattedImage = getImage(
                    image.childImageSharp.gatsbyImageData
                  );

                  return (
                    <div key={index}>
                      <GatsbyImage
                        as="span"
                        alt={name}
                        loading="eager"
                        image={formattedImage!}
                        className={styles.Careers_spotlight_people_image}
                      />
                      <p className={styles.Careers_spotlight_people_name}>
                        {name}
                      </p>
                      <p className={styles.Careers_spotlight_people_team}>
                        {team}
                      </p>
                      <Link
                        to={link}
                        className={styles.Careers_spotlight_people_link}
                      >
                        Read {sex === "male" ? "his" : "her"} story{" "}
                        <BrandArrow />
                      </Link>
                    </div>
                  );
                }
              )}
            </div>

            <div
              className={cx(
                styles.Careers_customersGrid_mobile,
                styles.Careers_spotlight_people_mobile
              )}
            >
              {pageData.spotlight.map(
                ({ sex, image, team, name, link }: any, index: number) => {
                  const formattedImage = getImage(
                    image.childImageSharp.gatsbyImageData
                  );

                  return (
                    <div
                      key={index}
                      className={cx(
                        styles.Careers_customersGrid_item,
                        styles.Careers_customersGrid_mobile_item,
                        {
                          [styles.Careers_customersGrid_mobile_item_active]:
                            activeImage === index,
                        }
                      )}
                    >
                      <GatsbyImage
                        as="span"
                        alt={name}
                        loading="eager"
                        image={formattedImage!}
                        className={styles.Careers_spotlight_people_image}
                      />
                      <p className={styles.Careers_spotlight_people_name}>
                        {name}
                      </p>
                      <div
                        className={styles.Careers_spotlight_people_mobile_text}
                      >
                        <p className={styles.Careers_spotlight_people_team}>
                          {team}
                        </p>
                        <Link
                          to={link}
                          className={styles.Careers_spotlight_people_link}
                        >
                          Read {sex === "male" ? "his" : "her"} story{" "}
                          <BrandArrow />
                        </Link>
                      </div>
                    </div>
                  );
                }
              )}
              <div className={styles.Careers_selectors}>
                {pageData.spotlight.map((_: any, index: number) => (
                  <div
                    key={index}
                    className={cx(styles.Careers_selector, {
                      [styles.Careers_selector_selected]: activeImage === index,
                    })}
                    onClick={() => setActiveImage(index)}
                  ></div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </section>

      <section className={styles.Careers_benefits}>
        <div className={styles.Careers_benefits_heading}>
          <h2 className={styles.Careers_title}>Our benefits</h2>
        </div>
        <HighlightCard size="large" items={CAREERS_DATA.BENEFITS} />
      </section>

      <section id="roles" className={styles.Careers_roles}>
        <div className={styles.Careers_roles_container}>
          <div className={styles.Careers_roles_heading}>
            <h2 className={styles.Careers_title}>All open roles</h2>
          </div>
          {formattedOpenings.length > 0 ? (
            <>
              <Table
                className={styles.Careers_roles_table}
                onRowClick={(data) => navigate(`/careers/${data.slug}`)}
                config={{
                  dataSchema: [
                    {
                      accessor: "position",
                      name: "Role",
                    },
                    {
                      accessor: "team",
                      name: "Team",
                    },
                    {
                      accessor: "location",
                      name: "Location",
                    },
                    {
                      accessor: "type",
                      name: "Type",
                    },
                  ],
                }}
                data={formattedOpenings}
              />
              <section className={styles.Careers_roles_mobile}>
                {formattedOpenings.map(
                  ({ position, type, team, location, slug }: any) => (
                    <Link
                      to={`/careers/${slug}`}
                      className={styles.Careers_roles_mobile_card}
                      key={slug}
                    >
                      <div className={styles.Careers_roles_mobile_card_row}>
                        <h3>{position}</h3>
                        <p>{type}</p>
                      </div>
                      <div className={styles.Careers_roles_mobile_card_row}>
                        <p>{team}</p>
                        <p>{location}</p>
                      </div>
                    </Link>
                  )
                )}
              </section>
            </>
          ) : (
            <p className={styles.Careers_roles_empty}>No open roles.</p>
          )}
        </div>
      </section>

      <GetStarted
        buttonLink={BRASS_TALENT_NETWORK_FORM}
        buttonText="Join the Brass Talent Network"
        title="Join our network of talents"
        desc="Don’t see any role that interests you? Join the brass talent network and be the first to know about open roles."
      />
    </>
  );
};

export const pageQuery = graphql`
  query CareersPageQuery {
    allJavascriptFrontmatter(
      filter: { frontmatter: { name: { eq: "careers" } } }
    ) {
      edges {
        node {
          frontmatter {
            heroImages {
              img {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            customerData {
              img {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            spotlight {
              sex
              link
              name
              team
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }

    careers: allStrapiCareer {
      edges {
        node {
          data {
            id
            attributes {
              aboutApplicant
              city
              country
              introduction
              link
              position
              date
              responsibilities
              role
              slug
              team
              type
            }
          }
        }
      }
    }
  }
`;

export default CareersPage;
