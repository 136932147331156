import React from "react";
import cx from "classnames";
import { uniqueId } from "lodash";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import * as styles from "./Hero.module.scss";

interface HeroProps {
  images: { img: any }[];
}

const Hero: React.FC<HeroProps> = ({ images }) => {
  return (
    <section className={styles.Hero}>
      <div className={styles.Hero_container}>
        <div className={cx(styles.Hero_images, styles.Hero_images_one)}>
          {images.slice(0, 2).map(({ img }) => {
            const image = getImage(img.childImageSharp.gatsbyImageData);
            return (
              <div key={uniqueId()} className={styles.Hero_images_one_image}>
                {image && <GatsbyImage image={image} alt={""} as="span" />}
              </div>
            );
          })}
        </div>
        <div className={styles.Hero_header}>
          <span className={styles.Hero_subtitle}>BRASS CAREERS</span>
          <h2 className={styles.Hero_title}>We are mission-driven</h2>

          <p className={styles.Hero_body}>
            We believe economic prosperity is possible. We also believe
            permission-less and successful entrepreneurship in emerging markets
            is possible.
            <br />
            <br />
            Our mission is making economic prosperity accessible to everyone,
            and we are looking for the best and right people that can help us
            accomplish this.
            <br />
            <br />
            We value unique contributions, embrace diversity in people and ideas
            and welcome everyone.
          </p>
        </div>
        <div className={cx(styles.Hero_images, styles.Hero_images_two)}>
          {images.slice(2).map(({ img }) => {
            const image = getImage(img.childImageSharp.gatsbyImageData);
            return (
              <div key={uniqueId()} className={styles.Hero_images_two_image}>
                {image && <GatsbyImage image={image} alt={""} as="span" />}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Hero;
