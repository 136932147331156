// extracted by mini-css-extract-plugin
export var CustomersGrid = "CustomersGrid-module--CustomersGrid--oFXjW";
export var CustomersGrid_item = "CustomersGrid-module--CustomersGrid_item--T8Sdd";
export var CustomersGrid_laptop = "CustomersGrid-module--CustomersGrid_laptop--fL3iM";
export var CustomersGrid_mobile = "CustomersGrid-module--CustomersGrid_mobile--pk8kL";
export var CustomersGrid_mobile_item = "CustomersGrid-module--CustomersGrid_mobile_item--buN7B";
export var CustomersGrid_mobile_item_active = "CustomersGrid-module--CustomersGrid_mobile_item_active--R+526";
export var CustomersGrid_selector = "CustomersGrid-module--CustomersGrid_selector--7C082";
export var CustomersGrid_selector_selected = "CustomersGrid-module--CustomersGrid_selector_selected--MWHJ-";
export var CustomersGrid_selectors = "CustomersGrid-module--CustomersGrid_selectors--cdzdi";
export var container = "CustomersGrid-module--container--NtwVE";
export var gatsbyImageWrapperConstrained = "CustomersGrid-module--gatsby-image-wrapper-constrained--cWmX0";
export var gridSpan1010 = "CustomersGrid-module--grid-span-10-10--GkVhe";
export var gridSpan1011 = "CustomersGrid-module--grid-span-10-11--yzl8y";
export var gridSpan102 = "CustomersGrid-module--grid-span-10-2--CNwCs";
export var gridSpan103 = "CustomersGrid-module--grid-span-10-3--7kzLr";
export var gridSpan104 = "CustomersGrid-module--grid-span-10-4--Ct6Ow";
export var gridSpan105 = "CustomersGrid-module--grid-span-10-5--WWPZB";
export var gridSpan106 = "CustomersGrid-module--grid-span-10-6--ak+3O";
export var gridSpan107 = "CustomersGrid-module--grid-span-10-7--9Ksa0";
export var gridSpan108 = "CustomersGrid-module--grid-span-10-8--WXSah";
export var gridSpan109 = "CustomersGrid-module--grid-span-10-9--sY9MI";
export var gridSpan110 = "CustomersGrid-module--grid-span-1-10--I+AaF";
export var gridSpan111 = "CustomersGrid-module--grid-span-1-11--a3LC1";
export var gridSpan1110 = "CustomersGrid-module--grid-span-11-10--vrtK3";
export var gridSpan1111 = "CustomersGrid-module--grid-span-11-11--ztyyp";
export var gridSpan112 = "CustomersGrid-module--grid-span-11-2--UqTyI";
export var gridSpan113 = "CustomersGrid-module--grid-span-11-3--BFRGN";
export var gridSpan114 = "CustomersGrid-module--grid-span-11-4--J1oNw";
export var gridSpan115 = "CustomersGrid-module--grid-span-11-5--z1m7+";
export var gridSpan116 = "CustomersGrid-module--grid-span-11-6--HtRJm";
export var gridSpan117 = "CustomersGrid-module--grid-span-11-7--t6RvA";
export var gridSpan118 = "CustomersGrid-module--grid-span-11-8--Oro8w";
export var gridSpan119 = "CustomersGrid-module--grid-span-11-9--uoXYO";
export var gridSpan12 = "CustomersGrid-module--grid-span-1-2--p3xPA";
export var gridSpan1210 = "CustomersGrid-module--grid-span-12-10--6zAMx";
export var gridSpan1211 = "CustomersGrid-module--grid-span-12-11--htJGw";
export var gridSpan122 = "CustomersGrid-module--grid-span-12-2--vbDy7";
export var gridSpan123 = "CustomersGrid-module--grid-span-12-3--QzKEx";
export var gridSpan124 = "CustomersGrid-module--grid-span-12-4--shGUQ";
export var gridSpan125 = "CustomersGrid-module--grid-span-12-5--l7Fv4";
export var gridSpan126 = "CustomersGrid-module--grid-span-12-6--1AZkh";
export var gridSpan127 = "CustomersGrid-module--grid-span-12-7--use88";
export var gridSpan128 = "CustomersGrid-module--grid-span-12-8--MY2MY";
export var gridSpan129 = "CustomersGrid-module--grid-span-12-9--gAnn8";
export var gridSpan13 = "CustomersGrid-module--grid-span-1-3--3YmMz";
export var gridSpan14 = "CustomersGrid-module--grid-span-1-4--vDyTK";
export var gridSpan15 = "CustomersGrid-module--grid-span-1-5--668En";
export var gridSpan16 = "CustomersGrid-module--grid-span-1-6--9dl26";
export var gridSpan17 = "CustomersGrid-module--grid-span-1-7--Kmxk+";
export var gridSpan18 = "CustomersGrid-module--grid-span-1-8--L1PkG";
export var gridSpan19 = "CustomersGrid-module--grid-span-1-9--WPM2E";
export var gridSpan210 = "CustomersGrid-module--grid-span-2-10--FqS4F";
export var gridSpan211 = "CustomersGrid-module--grid-span-2-11--hj5G3";
export var gridSpan22 = "CustomersGrid-module--grid-span-2-2---2VP6";
export var gridSpan23 = "CustomersGrid-module--grid-span-2-3--D9n8u";
export var gridSpan24 = "CustomersGrid-module--grid-span-2-4--yRExm";
export var gridSpan25 = "CustomersGrid-module--grid-span-2-5--JdnDu";
export var gridSpan26 = "CustomersGrid-module--grid-span-2-6--ss-JV";
export var gridSpan27 = "CustomersGrid-module--grid-span-2-7--uSCKy";
export var gridSpan28 = "CustomersGrid-module--grid-span-2-8--pfozI";
export var gridSpan29 = "CustomersGrid-module--grid-span-2-9--iQnkJ";
export var gridSpan310 = "CustomersGrid-module--grid-span-3-10--T3+GR";
export var gridSpan311 = "CustomersGrid-module--grid-span-3-11--W6TcI";
export var gridSpan32 = "CustomersGrid-module--grid-span-3-2--imLed";
export var gridSpan33 = "CustomersGrid-module--grid-span-3-3--4vPUV";
export var gridSpan34 = "CustomersGrid-module--grid-span-3-4---DHX4";
export var gridSpan35 = "CustomersGrid-module--grid-span-3-5--TqCRg";
export var gridSpan36 = "CustomersGrid-module--grid-span-3-6--WNTl4";
export var gridSpan37 = "CustomersGrid-module--grid-span-3-7--6oUUc";
export var gridSpan38 = "CustomersGrid-module--grid-span-3-8--oxBiG";
export var gridSpan39 = "CustomersGrid-module--grid-span-3-9--JLSoP";
export var gridSpan410 = "CustomersGrid-module--grid-span-4-10--D5rMm";
export var gridSpan411 = "CustomersGrid-module--grid-span-4-11--mstpn";
export var gridSpan42 = "CustomersGrid-module--grid-span-4-2--1mL2a";
export var gridSpan43 = "CustomersGrid-module--grid-span-4-3--hd-5+";
export var gridSpan44 = "CustomersGrid-module--grid-span-4-4---corR";
export var gridSpan45 = "CustomersGrid-module--grid-span-4-5--IlAyc";
export var gridSpan46 = "CustomersGrid-module--grid-span-4-6--Hhdg6";
export var gridSpan47 = "CustomersGrid-module--grid-span-4-7--TKf3n";
export var gridSpan48 = "CustomersGrid-module--grid-span-4-8--rFFiO";
export var gridSpan49 = "CustomersGrid-module--grid-span-4-9--7UMyL";
export var gridSpan510 = "CustomersGrid-module--grid-span-5-10--30ClC";
export var gridSpan511 = "CustomersGrid-module--grid-span-5-11--YyYhO";
export var gridSpan52 = "CustomersGrid-module--grid-span-5-2--v9K3s";
export var gridSpan53 = "CustomersGrid-module--grid-span-5-3--Bypdq";
export var gridSpan54 = "CustomersGrid-module--grid-span-5-4--r0ePd";
export var gridSpan55 = "CustomersGrid-module--grid-span-5-5--wOD0w";
export var gridSpan56 = "CustomersGrid-module--grid-span-5-6--lnA4i";
export var gridSpan57 = "CustomersGrid-module--grid-span-5-7--HhI1b";
export var gridSpan58 = "CustomersGrid-module--grid-span-5-8--iws3p";
export var gridSpan59 = "CustomersGrid-module--grid-span-5-9--uodcG";
export var gridSpan610 = "CustomersGrid-module--grid-span-6-10--ZhomR";
export var gridSpan611 = "CustomersGrid-module--grid-span-6-11--RjqV-";
export var gridSpan62 = "CustomersGrid-module--grid-span-6-2--RFzwq";
export var gridSpan63 = "CustomersGrid-module--grid-span-6-3--ZAh4I";
export var gridSpan64 = "CustomersGrid-module--grid-span-6-4--JWwl-";
export var gridSpan65 = "CustomersGrid-module--grid-span-6-5--hSHF0";
export var gridSpan66 = "CustomersGrid-module--grid-span-6-6--rphxx";
export var gridSpan67 = "CustomersGrid-module--grid-span-6-7--UQ1tS";
export var gridSpan68 = "CustomersGrid-module--grid-span-6-8--8lvp2";
export var gridSpan69 = "CustomersGrid-module--grid-span-6-9--QDOMF";
export var gridSpan710 = "CustomersGrid-module--grid-span-7-10--VoEUT";
export var gridSpan711 = "CustomersGrid-module--grid-span-7-11--F3Ngo";
export var gridSpan72 = "CustomersGrid-module--grid-span-7-2--wUzua";
export var gridSpan73 = "CustomersGrid-module--grid-span-7-3--HmjRl";
export var gridSpan74 = "CustomersGrid-module--grid-span-7-4--8Nfcj";
export var gridSpan75 = "CustomersGrid-module--grid-span-7-5--LDi3J";
export var gridSpan76 = "CustomersGrid-module--grid-span-7-6---ULSR";
export var gridSpan77 = "CustomersGrid-module--grid-span-7-7--F66d6";
export var gridSpan78 = "CustomersGrid-module--grid-span-7-8--Bb2f2";
export var gridSpan79 = "CustomersGrid-module--grid-span-7-9--oeJYr";
export var gridSpan810 = "CustomersGrid-module--grid-span-8-10--krPVj";
export var gridSpan811 = "CustomersGrid-module--grid-span-8-11--EzYh8";
export var gridSpan82 = "CustomersGrid-module--grid-span-8-2--bPg5B";
export var gridSpan83 = "CustomersGrid-module--grid-span-8-3--J7TgF";
export var gridSpan84 = "CustomersGrid-module--grid-span-8-4--+N+VL";
export var gridSpan85 = "CustomersGrid-module--grid-span-8-5--EZx1G";
export var gridSpan86 = "CustomersGrid-module--grid-span-8-6--z3JHL";
export var gridSpan87 = "CustomersGrid-module--grid-span-8-7--n1BoB";
export var gridSpan88 = "CustomersGrid-module--grid-span-8-8--89Jaj";
export var gridSpan89 = "CustomersGrid-module--grid-span-8-9--4bz+F";
export var gridSpan910 = "CustomersGrid-module--grid-span-9-10--j4l9y";
export var gridSpan911 = "CustomersGrid-module--grid-span-9-11--ZMkdT";
export var gridSpan92 = "CustomersGrid-module--grid-span-9-2--hBUWc";
export var gridSpan93 = "CustomersGrid-module--grid-span-9-3--Juwva";
export var gridSpan94 = "CustomersGrid-module--grid-span-9-4--2PAWS";
export var gridSpan95 = "CustomersGrid-module--grid-span-9-5--ltiWB";
export var gridSpan96 = "CustomersGrid-module--grid-span-9-6--2dKl2";
export var gridSpan97 = "CustomersGrid-module--grid-span-9-7--hNZVj";
export var gridSpan98 = "CustomersGrid-module--grid-span-9-8--+d2Mq";
export var gridSpan99 = "CustomersGrid-module--grid-span-9-9--qppKY";
export var textBreak = "CustomersGrid-module--text-break--gTiHL";